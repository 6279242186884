<template>
    <main class="staff">
        <router-link class="staff__link staff__link_back link link_red"
            v-if="isReportTotalLinkVisible"
            :to="{ name: 'StaffTotal'}"
        >
            Вернуться к общим данным о штатной расстановке
        </router-link>
        <h2 class="sr-only">Данные отчета "Штатная расстановка"</h2>
        <spinner class="staff__spinner" v-if="status === 'loading'" />
        <error-message class="staff__error-message" v-else-if="status === 'error'">
            Не удалось загрузить информацию о штатной расстановке.
        </error-message>
        <p class="staff__date h2" v-if="dateOfReport">Дата отчета: {{dateOfReport | dateTextLong}}</p>
        <div class="staff__filters" v-if="status === 'success' && isFiltersVisible">
            <fieldset class="staff__radio-group staff__radio-group_filters">
                <legend class="staff__legend sr-only">Фильтр групп должностей</legend>
                <radio class="staff__radio staff__radio_filter"
                    v-for="filter in filters"
                    :key="filter.id"
                    :val="filter.id"
                    name="filter"
                    v-model="filterModel"
                >
                    {{filter.title}}
                </radio>
            </fieldset>
        </div>
        <router-view
            class="staff__data"
            :status="status"
            :filters="filters"
            :filterId="filterId"
            :isFiltersVisible="isFiltersVisible"
            :isReportTotalLinkVisible="isReportTotalLinkVisible"
            :departments="departments"
            :userStaffUnits="userStaffUnits"
            :total="total"
            :calcPositionsTotal="calcPositionsTotal"
        />
    </main>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
    import parseISO from "date-fns/parseISO";
    import { dateTextLong } from "@/helpers/daterange";

    export default {
        name: "Staff",
        data() {
            return {
                status: "loading"
            };
        },
        computed: {
            ...mapState({
                data: state => state.staff.data,
                filterId: state => state.staff.filterId,
                isFiltersVisible: state => state.staff.isFiltersVisible,
                isReportTotalLinkVisible: state => state.ui.isReportTotalLinkVisible
            }),
            ...mapGetters([
                "userRestaurants",
                "userStaffDepartments"
            ]),
            filters() {
                return [
                    { id: "all", title: "Все" },
                    { id: "administrator", title: "Управляющий персонал" },
                    { id: "ordinary", title: "Линейный персонал" }
                ];
            },
            filterModel: {
                get() {
                    return this.filterId;
                },
                set(filterId) {
                    this.setStaffFilter(filterId);
                }
            },
            userStaffUnits() {
                if (this.userRestaurants && this.userStaffDepartments) {
                    return {
                        ids: [
                            ...this.userRestaurants?.ids,
                            ...this.userStaffDepartments?.ids
                        ],
                        byId: {
                            ...this.userRestaurants?.byId,
                            ...this.userStaffDepartments?.byId
                        }
                    };
                }
            },
            departments() {
                if (this.data?.restaurants) {
                    return Object.values(this.data.restaurants);
                }
            },
            total() {
                return this.data?.total;
            },
            dateOfReport() {
                if (this.data?.date_of_report) {
                    return parseISO(this.data?.date_of_report);
                }
            }
        },
        methods: {
            ...mapMutations([
                "setStaffFilter"
            ]),
            ...mapActions([
                "requestStaff"
            ]),
            showReport() {
                this.status = "success";
            },
            loadStaff() {
                this.status = "loading";
                this.requestStaff().then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            },
            calcPositionsTotal(positions) {
                return positions.reduce((summary, position) => {
                    summary.number += Number(position.staff_num);
                    summary.number_closed += Number(position.closed_vacancies);
                    summary.number_open += Number(position.opened_vacancies);
                    summary.sum_fot += Number(position.wage_fund);

                    return summary;
                }, {
                    number: 0,
                    number_closed: 0,
                    number_open: 0,
                    sum_fot: 0
                });
            }
        },
        filters: {
            dateTextLong
        },
        created() {
            if (this.departments) {
                this.showReport();
            } else {
                this.loadStaff();
            }
        }
    }
</script>

<style lang="scss">
    .staff__radio-group {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;

        @include desktop {
            flex-direction: row;
            margin-bottom: 15px;
        }
    }
    .staff__radio {
        & + & {
            margin-top: 5px;

            @include desktop {
                margin: 0 0 0 20px;
            }
        }
    }
    .staff__link {
        &_back {
            display: inline-block;
            margin-bottom: 10px;

            @include desktop {
                margin-bottom: 15px;
            }
        }
    }
    .staff__date {
        margin-bottom: 15px;
    }
</style>
